<template>
<div>
     <el-dialog title="PDF 预览"
  :visible.sync="viewVisible" width="70%" center
   @close='closeDialog'>
   <div style="margin-bottom: 15px; text-align: right;margin-right:100px">
        <el-button type="primary" size="small" @click.stop="previousPage">
           上一页
         </el-button>
         <el-button type="primary" size="small" @click.stop="nextPage">
         下一页
        </el-button>
     <span style="margin-left:10px">当前第{{pdfPage}}页 / 共{{pageCount}}页</span>
   </div>
   <div class="showpdf">
     <pdf
       :src="src"
       :page="pdfPage"
       @num-pages="pageCount = $event"
       @page-loaded="pdfPage = $event"
       style="display: inline-block; width: 100%"
    ></pdf>
  </div>
  
</el-dialog>
</div>
</template>
<script>
 import pdf from 'vue-pdf';
 
 export default {
    components: {
      pdf
    },
    data() {
    return {
    //PDF预览
        viewVisible: false,
        src: null,
        pdfPage : 1,
        pageCount: 0,
  }
 },
 methods:{
  //PDF预览
      previewPDF(url){
      //  var loadingTask = pdf.createLoadingTask('http://file.gp58.com/file/2018-11-14/111405.pdf');
      var loadingTask = pdf.createLoadingTask(url);
        this.src = loadingTask;
        this.src.promise.then(pdf => {

            this.pageCount = pdf.numPages;

        });
        this.viewVisible = true;
      },

      //关闭窗口初始化PDF页码
      closeDialog(){
        this.pdfPage = 1;
      },

      //PDF改变页数
      previousPage(){
        var p = this.pdfPage
        p = p>1?p-1:this.pageCount
        this.pdfPage = p
      },
      nextPage(){
        var p = this.pdfPage
        p = p<this.pageCount?p+1:1
        this.pdfPage = p
      }
 }
  }
</script>
<style scoped>
.showpdf{height: 500px;overflow: auto;}
</style>